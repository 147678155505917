export const isProtectedRoute = (pathName: string): boolean => {
  return (
    pathName.startsWith("/account") ||
    pathName.startsWith("/admin") ||
    pathName.startsWith("/checkout")
  );
};

export const isAdminRoute = (pathName: string): boolean => {
  return pathName.startsWith("/admin");
};

export const getShouldNotRetry = (statusCode: number | undefined): boolean => {
  if (!statusCode) return false;

  return [401, 403, 404].includes(statusCode);
};
